import TokenAmount from './TokenAmount';

export default function convertFokMinPriceToReceivedAmount({
  srcAmount,
  egressFee,
  ingressFee,
  minPrice,
  destTokenDecimals,
}: {
  srcAmount: TokenAmount;
  egressFee: TokenAmount;
  ingressFee: TokenAmount;
  minPrice: string;
  destTokenDecimals: number;
}) {
  const value =
    (srcAmount.toNumber() - ingressFee.toNumber()) * Number(minPrice) - egressFee.toNumber();

  return TokenAmount.fromWholeUnits(Math.max(value, 0), destTokenDecimals);
}
